<template>
  <HomePage />
</template>

<script>
import HomePage from '../components/HomePage'
import {mapActions,mapState} from "vuex"
export default {
  name: 'Home',
  components: {
    HomePage,
  },
  async created() {

  },
  computed: {
  },
  methods: {

  }
}
</script>
