<template>
  <v-main id="master_app">
    <v-app id="app">
        <Loader />
      <div class="">
      <!-- <div class="container-fluid swiss-bg"> -->
        <div>
        <core-top-bar/>
        <core-view />
        <core-footer />
        </div>
      </div>
    </v-app>
  </v-main>
</template>

<script>
import {mapActions,mapState} from "vuex"
export default {
  name: "App",
  data: () => ({
    loader: true,
  }),
  components: {
    CoreFooter: () => import("@/components/core/Footer"),
    CoreTopBar: () => import("@/components/core/TopBar"),
    CoreView: () => import("@/components/core/View"),
    Loader: ()=>import('../components/helpers/loader'),
  },
  async created() {
  },
  computed: {

  },
  methods: {

  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
#master_app #app{
    font-family: 'Poppins', sans-serif;
}
</style>